import gql from "graphql-tag";

export const PAPER_TYPES = gql`
  query paperTypes {
    paperTypes {
      id
      name
      englishName
    }
  }
`;
