<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    item-text="name"
    item-value="id"
    :loading="loading"
    :items="items"
    :label="$t('hr.paper.type')"
    color="tertiary"
    clearable
  >
    <template slot="selection" slot-scope="data">
      {{ getName(data.item) }}
    </template>
    <template slot="item" slot-scope="data">
      {{ getName(data.item) }}
    </template>
  </v-autocomplete>
</template>

<script>
  import { PAPER_TYPES } from "../query";

  export default {
    inheritAttrs: false,
    name: "paper-type-picker",
    props: {
      value: String
    },
    data: () => ({
      loading: false,
      selected: null,
      items: []
    }),
    watch: {
      value: {
        handler(v) {
          this.selected = v;
        },
        immediate: true
      }
    },
    methods: {
      getName(item) {
        return this.$store.state.auth.language === "tr" ? item.name : item.englishName || item.name;
      },
      async fetchItems() {
        this.loading = true;
        await this.$apollo
          .query({
            query: PAPER_TYPES
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.items = data.paperTypes;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>

<style scoped></style>
